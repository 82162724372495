// extracted by mini-css-extract-plugin
export var arrow = "casesBlock-module--arrow--87fb2";
export var arrowImg = "casesBlock-module--arrowImg--caf8d";
export var caseBackdrop = "casesBlock-module--caseBackdrop--bdb70";
export var caseImg = "casesBlock-module--caseImg--a5d36";
export var caseItem = "casesBlock-module--caseItem--00f01";
export var caseLink = "casesBlock-module--caseLink--687d2";
export var caseWrapper = "casesBlock-module--caseWrapper--73da8";
export var catalog = "casesBlock-module--catalog--1ded3";
export var container = "casesBlock-module--container--9ee7c";
export var description = "casesBlock-module--description--b5c1b";
export var homePage = "casesBlock-module--homePage--3c4f3";
export var inner = "casesBlock-module--inner--405ef";
export var innerBreadcrumbs = "casesBlock-module--innerBreadcrumbs--7fec1";
export var innerPadding = "casesBlock-module--innerPadding--7e8fc";
export var title = "casesBlock-module--title--d291c";